export const GET_ADMIN_GOVERNING_REQUEST = "GET_ADMIN_GOVERNING_REQUEST";
export const GET_ADMIN_GOVERNING_SUCCESS = "GET_ADMIN_GOVERNING_SUCCESS";
export const GET_ADMIN_GOVERNING_FAIL = "GET_GOVERNING_GOVERNING_FAIL";

export const ADD_GOVERNINGHEADER_GOVERNING_REQUEST = "ADD_GOVERNINGHEADER_GOVERNING_REQUEST";
export const ADD_GOVERNINGHEADER_GOVERNING_SUCCESS = "ADD_GOVERNINGHEADER_GOVERNING_SUCCESS";
export const ADD_GOVERNINGHEADER_GOVERNING_FAIL = "ADD_GOVERNINGHEADER_GOVERNING_FAIL";
export const ADD_GOVERNINGHEADER_GOVERNING_RESET = "ADD_GOVERNINGHEADER_GOVERNING_RESET";

export const ADD_GOVERNINGBODY_GOVERNING_REQUEST = "ADD_GOVERNINGBODY_GOVERNING_REQUEST";
export const ADD_GOVERNINGBODY_GOVERNING_SUCCESS = "ADD_GOVERNINGBODY_GOVERNING_SUCCESS";
export const ADD_GOVERNINGBODY_GOVERNING_FAIL = "ADD_GOVERNINGBODY_GOVERNING_FAIL";
export const ADD_GOVERNINGBODY_GOVERNING_RESET = "ADD_GOVERNINGBODY_GOVERNING_RESET";

export const CLEAR_ERRORS = "CLEAR_ERRORS";