export const GET_ADMIN_HOME_REQUEST = "GET_ADMIN_HOME_REQUEST";
export const GET_ADMIN_HOME_SUCCESS = "GET_ADMIN_HOME_SUCCESS";
export const GET_ADMIN_HOME_FAIL = "GET_ADMIN_HOME_FAIL";

export const ADD_HOMEHEADER_HOME_REQUEST = "ADD_HOMEHEADER_HOME_REQUEST";
export const ADD_HOMEHEADER_HOME_SUCCESS = "ADD_HOMEHEADER_HOME_SUCCESS";
export const ADD_HOMEHEADER_HOME_FAIL = "ADD_HOMEHEADER_HOME_FAIL";
export const ADD_HOMEHEADER_HOME_RESET = "ADD_HOMEHEADER_HOME_RESET";

export const ADD_HIGHTLIGHT_HOME_REQUEST = "ADD_HIGHTLIGHT_HOME_REQUEST";
export const ADD_HIGHTLIGHT_HOME_SUCCESS = "ADD_HIGHTLIGHT_HOME_SUCCESS";
export const ADD_HIGHTLIGHT_HOME_FAIL = "ADD_HIGHTLIGHT_HOME_FAIL";
export const ADD_HIGHTLIGHT_HOME_RESET = "ADD_HIGHTLIGHT_HOME_RESET";

export const ADD_ABOUT_HOME_REQUEST = "ADD_ABOUT_HOME_REQUEST";
export const ADD_ABOUT_HOME_SUCCESS = "ADD_ABOUT_HOME_SUCCESS";
export const ADD_ABOUT_HOME_FAIL = "ADD_ABOUT_HOME_FAIL";
export const ADD_ABOUT_HOME_RESET = "ADD_ABOUT_HOME_RESET";

export const ADD_SERVICEHEAD_HOME_REQUEST = "ADD_SERVICEHEAD_HOME_REQUEST";
export const ADD_SERVICEHEAD_HOME_SUCCESS = "ADD_SERVICEHEAD_HOME_SUCCESS";
export const ADD_SERVICEHEAD_HOME_FAIL = "ADD_SERVICEHEAD_HOME_FAIL";
export const ADD_SERVICEHEAD_HOME_RESET = "ADD_SERVICEHEAD_HOME_RESET";

export const ADD_SERVICE_HOME_REQUEST = "ADD_SERVICE_HOME_REQUEST";
export const ADD_SERVICE_HOME_SUCCESS = "ADD_SERVICE_HOME_SUCCESS";
export const ADD_SERVICE_HOME_FAIL = "ADD_SERVICE_HOME_FAIL";
export const ADD_SERVICE_HOME_RESET = "ADD_SERVICE_HOME_RESET";

export const UPDATE_SERVICE_HOME_REQUEST = "UPDATE_SERVICE_HOME_REQUEST";
export const UPDATE_SERVICE_HOME_SUCCESS = "UPDATE_SERVICE_HOME_SUCCESS";
export const UPDATE_SERVICE_HOME_FAIL = "UPDATE_SERVICE_HOME_FAIL";
export const UPDATE_SERVICE_HOME_RESET = "UPDATE_SERVICE_HOME_RESET";

export const ADD_VISION_HOME_REQUEST = "ADD_VISION_HOME_REQUEST";
export const ADD_VISION_HOME_SUCCESS = "ADD_VISION_HOME_SUCCESS";
export const ADD_VISION_HOME_FAIL = "ADD_VISION_HOME_FAIL";
export const ADD_VISION_HOME_RESET = "ADD_VISION_HOME_RESET";


export const ADD_JOINUS_HOME_REQUEST = "ADD_JOINUS_HOME_REQUEST";
export const ADD_JOINUS_HOME_SUCCESS = "ADD_JOINUS_HOME_SUCCESS";
export const ADD_JOINUS_HOME_FAIL = "ADD_JOINUS_HOME_FAIL";
export const ADD_JOINUS_HOME_RESET = "ADD_JOINUS_HOME_RESET";

export const ADD_MEMBER_HOME_REQUEST = "ADD_MEMBER_HOME_REQUEST";
export const ADD_MEMBER_HOME_SUCCESS = "ADD_MEMBER_HOME_SUCCESS";
export const ADD_MEMBER_HOME_FAIL = "ADD_MEMBER_HOME_FAIL";
export const ADD_MEMBER_HOME_RESET = "ADD_MEMBER_HOME_RESET";

export const UPDATE_MEMBER_HOME_REQUEST = "UPDATE_MEMBER_HOME_REQUEST";
export const UPDATE_MEMBER_HOME_SUCCESS = "UPDATE_MEMBER_HOME_SUCCESS";
export const UPDATE_MEMBER_HOME_FAIL = "UPDATE_MEMBER_HOME_FAIL";
export const UPDATE_MEMBER_HOME_RESET = "UPDATE_MEMBER_HOME_RESET";


export const DELETE_MEMBER_HOME_REQUEST = "DELETE_MEMBER_HOME_REQUEST";
export const DELETE_MEMBER_HOME_SUCCESS = "DELETE_MEMBER_HOME_SUCCESS";
export const DELETE_MEMBER_HOME_FAIL = "DELETE_MEMBER_HOME_FAIL";
export const DELETE_MEMBER_HOME_RESET = "DELETE_MEMBER_HOME_RESET";


export const CLEAR_ERRORS = "CLEAR_ERRORS";