export const GET_ADMIN_OTHER_REQUEST = "GET_ADMIN_OTHER_REQUEST";
export const GET_ADMIN_OTHER_SUCCESS = "GET_ADMIN_OTHER_SUCCESS";
export const GET_ADMIN_OTHER_FAIL = "GET_ADMIN_OTHER_FAIL";

export const ADD_POLICY_REQUEST = "ADD_POLICY_REQUEST";
export const ADD_POLICY_SUCCESS = "ADD_POLICY_SUCCESS";
export const ADD_POLICY_FAIL = "ADD_POLICY_FAIL";
export const ADD_POLICY_RESET = "ADD_POLICY_RESET";

export const ADD_TERMS_REQUEST = "ADD_TERMS_REQUEST";
export const ADD_TERMS_SUCCESS = "ADD_TERMS_SUCCESS";
export const ADD_TERMS_FAIL = "ADD_TERMS_FAIL";
export const ADD_TERMS_RESET = "ADD_TERMS_RESET";

export const ADD_METADATA_REQUEST = "ADD_METADATA_REQUEST";
export const ADD_METADATA_SUCCESS = "ADD_METADATA_SUCCESS";
export const ADD_METADATA_FAIL = "ADD_METADATA_FAIL";
export const ADD_METADATA_RESET = "ADD_METADATA_RESET";

export const ADD_SOCIAL_REQUEST = "ADD_SOCIAL_REQUEST";
export const ADD_SOCIAL_SUCCESS = "ADD_SOCIAL_SUCCESS";
export const ADD_SOCIAL_FAIL = "ADD_SOCIAL_FAIL";
export const ADD_SOCIAL_RESET = "ADD_SOCIAL_RESET";

export const GET_ADMIN_ANNOUNCEMENT_REQUEST = "GET_ADMIN_ANNOUNCEMENT_REQUEST";
export const GET_ADMIN_ANNOUNCEMENT_SUCCESS = "GET_ADMIN_ANNOUNCEMENT_SUCCESS";
export const GET_ADMIN_ANNOUNCEMENT_FAIL = "GET_ADMIN_ANNOUNCEMENT_FAIL";

export const ADD_ADMIN_ANNOUNCEMENT_REQUEST = "ADD_ADMIN_ANNOUNCEMENT_REQUEST";
export const ADD_ADMIN_ANNOUNCEMENT_SUCCESS = "ADD_ADMIN_ANNOUNCEMENT_SUCCESS";
export const ADD_ADMIN_ANNOUNCEMENT_FAIL = "ADD_ADMIN_ANNOUNCEMENT_FAIL";
export const ADD_ADMIN_ANNOUNCEMENT_RESET = "ADD_ADMIN_ANNOUNCEMENT_RESET";

export const SHOW_ANNOUNCEMENT_REQUEST = "SHOW_ANNOUNCEMENT_REQUEST";
export const SHOW_ANNOUNCEMENT_SUCCESS = "SHOW_ANNOUNCEMENT_SUCCESS";
export const SHOW_ANNOUNCEMENT_FAIL = "SHOW_ANNOUNCEMENT_FAIL";
export const SHOW_ANNOUNCEMENT_RESET = "SHOW_ANNOUNCEMENT_RESET";

export const CLEAR_ERRORS = "CLEAR_ERRORS"