export const GET_ADMIN_GRIEVANCE_REQUEST = "GET_ADMIN_GRIEVANCE_REQUEST";
export const GET_ADMIN_GRIEVANCE_SUCCESS = "GET_ADMIN_GRIEVANCE_SUCCESS";
export const GET_ADMIN_GRIEVANCE_FAIL = "GET_GRIEVANCE_GRIEVANCE_FAIL";

export const ADD_GRIEVANCEHEADER_GRIEVANCE_REQUEST = "ADD_GRIEVANCEHEADER_GRIEVANCE_REQUEST";
export const ADD_GRIEVANCEHEADER_GRIEVANCE_SUCCESS = "ADD_GRIEVANCEHEADER_GRIEVANCE_SUCCESS";
export const ADD_GRIEVANCEHEADER_GRIEVANCE_FAIL = "ADD_GRIEVANCEHEADER_GRIEVANCE_FAIL";
export const ADD_GRIEVANCEHEADER_GRIEVANCE_RESET = "ADD_GRIEVANCEHEADER_GRIEVANCE_RESET";

export const GET_ADMIN_GRIEVANCE_MESSAGES_REQUEST = "GET_ADMIN_GRIEVANCE_MESSAGES_REQUEST";
export const GET_ADMIN_GRIEVANCE_MESSAGES_SUCCESS = "GET_ADMIN_GRIEVANCE_MESSAGES_SUCCESS";
export const GET_ADMIN_GRIEVANCE_MESSAGES_FAIL = "GET_GRIEVANCE_GRIEVANCE_MESSAGES_FAIL";

export const UPDATE_ADMIN_GRIEVANCE_MESSAGES_REQUEST = "UPDATE_ADMIN_GRIEVANCE_MESSAGES_REQUEST";
export const UPDATE_ADMIN_GRIEVANCE_MESSAGES_SUCCESS = "UPDATE_ADMIN_GRIEVANCE_MESSAGES_SUCCESS";
export const UPDATE_ADMIN_GRIEVANCE_MESSAGES_FAIL = "UPDATE_GRIEVANCE_GRIEVANCE_MESSAGES_FAIL";
export const UPDATE_ADMIN_GRIEVANCE_MESSAGES_RESET = "UPDATE_GRIEVANCE_GRIEVANCE_MESSAGES_RESET";

export const CLEAR_ERRORS = "CLEAR_ERRORS";