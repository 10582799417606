import React from 'react';
import './Loader.css'

const Loader = () => {
  return (
    <div className='loader-body'>
      <span class="loader"></span>
    </div>
  )
}

export default Loader
