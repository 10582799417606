export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';

export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAIL = "LOGOUT_FAIL";

export const ADD_ADMIN_REQUEST = 'ADD_ADMIN_REQUEST';
export const ADD_ADMIN_SUCCESS = 'ADD_ADMIN_SUCCESS';
export const ADD_ADMIN_FAIL = 'ADD_ADMIN_FAIL';
export const ADD_ADMIN_RESET = 'ADD_ADMIN_RESET';

export const GET_ADMINS_REQUEST = 'GET_ADMINS_REQUEST';
export const GET_ADMINS_SUCCESS = 'GET_ADMINS_SUCCESS';
export const GET_ADMINS_FAIL = 'GET_ADMINS_FAIL';

export const LOAD_ADMIN_REQUEST = "LOAD_ADMIN_REQUEST"; 
export const LOAD_ADMIN_SUCCESS = "LOAD_ADMIN_SUCCESS"; 
export const LOAD_ADMIN_FAIL = "LOAD_ADMIN_FAIL";

export const CLEAR_ERRORS = "CLEAR_ERRORS";
