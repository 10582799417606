export const GET_ADMIN_GALLERY_REQUEST = "GET_ADMIN_GALLERY_REQUEST";
export const GET_ADMIN_GALLERY_SUCCESS = "GET_ADMIN_GALLERY_SUCCESS";
export const GET_ADMIN_GALLERY_FAIL = "GET_ADMIN_GALLERY_FAIL";

export const ADD_ADMIN_GALLERYHEADER_REQUEST = "ADD_ADMIN_GALLERYHEADER_REQUEST";
export const ADD_ADMIN_GALLERYHEADER_SUCCESS = "ADD_ADMIN_GALLERYHEADER_SUCCESS";
export const ADD_ADMIN_GALLERYHEADER_FAIL = "ADD_ADMIN_GALLERYHEADER_FAIL";
export const ADD_ADMIN_GALLERYHEADER_RESET = "ADD_ADMIN_GALLERYHEADER_RESET";

export const ADD_ADMIN_GALLERY_BODY_REQUEST = "ADD_ADMIN_GALLERY_BODY_REQUEST";
export const ADD_ADMIN_GALLERY_BODY_SUCCESS = "ADD_ADMIN_GALLERY_BODY_SUCCESS";
export const ADD_ADMIN_GALLERY_BODY_FAIL = "ADD_ADMIN_GALLERY_BODY_FAIL";
export const ADD_ADMIN_GALLERY_BODY_RESET = "ADD_ADMIN_GALLERY_BODY_RESET";

export const ADD_ADMIN_GALLERY_IMAGE_REQUEST = "ADD_ADMIN_GALLERY_IMAGE_REQUEST";
export const ADD_ADMIN_GALLERY_IMAGE_SUCCESS = "ADD_ADMIN_GALLERY_IMAGE_SUCCESS";
export const ADD_ADMIN_GALLERY_IMAGE_FAIL = "ADD_ADMIN_GALLERY_IMAGE_FAIL";
export const ADD_ADMIN_GALLERY_IMAGE_RESET = "ADD_ADMIN_GALLERY_IMAGE_RESET";

export const UPDATE_ADMIN_GALLERY_IMAGE_REQUEST = "UPDATE_ADMIN_GALLERY_IMAGE_REQUEST";
export const UPDATE_ADMIN_GALLERY_IMAGE_SUCCESS = "UPDATE_ADMIN_GALLERY_IMAGE_SUCCESS";
export const UPDATE_ADMIN_GALLERY_IMAGE_FAIL = "UPDATE_ADMIN_GALLERY_IMAGE_FAIL";
export const UPDATE_ADMIN_GALLERY_IMAGE_RESET = "UPDATE_ADMIN_GALLERY_IMAGE_RESET";

export const CLEAR_ERRORS = "CLEAR_ERRORS";