export const GET_ADMIN_SERVICES_REQUEST = "GET_ADMIN_SERVICES_REQUEST";
export const GET_ADMIN_SERVICES_SUCCESS = "GET_ADMIN_SERVICES_SUCCESS";
export const GET_ADMIN_SERVICES_FAIL = "GET_ADMIN_SERVICES_FAIL";


export const ADD_ADMIN_SERVICESHEADER_REQUEST = "ADD_ADMIN_SERVICESHEADER_REQUEST";
export const ADD_ADMIN_SERVICESHEADER_SUCCESS = "ADD_ADMIN_SERVICESHEADER_SUCCESS";
export const ADD_ADMIN_SERVICESHEADER_FAIL = "ADD_ADMIN_SERVICESHEADER_FAIL";
export const ADD_ADMIN_SERVICESHEADER_RESET = "ADD_ADMIN_SERVICESHEADER_RESET";

export const ADD_ADMIN_SERVICES_REQUEST = "ADD_ADMIN_SERVICES_REQUEST";
export const ADD_ADMIN_SERVICES_SUCCESS = "ADD_ADMIN_SERVICES_SUCCESS";
export const ADD_ADMIN_SERVICES_FAIL = "ADD_ADMIN_SERVICES_FAIL";
export const ADD_ADMIN_SERVICES_RESET = "ADD_ADMIN_SERVICES_RESET";

export const ADD_ADMIN_SERVICESIMAGE_REQUEST = "ADD_ADMIN_SERVICESIMAGE_REQUEST";
export const ADD_ADMIN_SERVICESIMAGE_SUCCESS = "ADD_ADMIN_SERVICESIMAGE_SUCCESS";
export const ADD_ADMIN_SERVICESIMAGE_FAIL = "ADD_ADMIN_SERVICESIMAGE_FAIL";
export const ADD_ADMIN_SERVICESIMAGE_RESET = "ADD_ADMIN_SERVICESIMAGE_RESET";


export const UPDATE_ADMIN_SERVICESIMAGE_REQUEST = "UPDATE_ADMIN_SERVICESIMAGE_REQUEST";
export const UPDATE_ADMIN_SERVICESIMAGE_SUCCESS = "UPDATE_ADMIN_SERVICESIMAGE_SUCCESS";
export const UPDATE_ADMIN_SERVICESIMAGE_FAIL = "UPDATE_ADMIN_SERVICESIMAGE_FAIL";
export const UPDATE_ADMIN_SERVICESIMAGE_RESET = "UPDATE_ADMIN_SERVICESIMAGE_RESET";

export const CLEAR_ERRORS = "CLEAR_ERRORS";