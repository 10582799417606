export const GET_ADMIN_CONTACT_REQUEST = "GET_ADMIN_CONTACT_REQUEST";
export const GET_ADMIN_CONTACT_SUCCESS = "GET_ADMIN_CONTACT_SUCCESS";
export const GET_ADMIN_CONTACT_FAIL = "GET_ADMIN_CONTACT_FAIL";

export const ADD_CONTACTHEADER_CONTACT_REQUEST = "ADD_CONTACTHEADER_CONTACT_REQUEST";
export const ADD_CONTACTHEADER_CONTACT_SUCCESS = "ADD_CONTACTHEADER_CONTACT_SUCCESS";
export const ADD_CONTACTHEADER_CONTACT_FAIL = "ADD_CONTACTHEADER_CONTACT_FAIL";
export const ADD_CONTACTHEADER_CONTACT_RESET = "ADD_CONTACTHEADER_CONTACT_RESET";

export const ADD_CONTACTINFO_CONTACT_REQUEST = "ADD_CONTACTINFO_CONTACT_REQUEST";
export const ADD_CONTACTINFO_CONTACT_SUCCESS = "ADD_CONTACTINFO_CONTACT_SUCCESS";
export const ADD_CONTACTINFO_CONTACT_FAIL = "ADD_CONTACTINFO_CONTACT_FAIL";
export const ADD_CONTACTINFO_CONTACT_RESET = "ADD_CONTACTINFO_CONTACT_RESET";

export const CLEAR_ERRORS = "CLEAR_ERRORS";